/**
 * Dummy class for replacement via `package.json` in browser builds.
 *
 * If we don't include these functions typeorm will throw an error on runtime
 * as well as during webpack builds.
 */
export class ConnectionOptionsEnvReader {
    async read() {
        throw new Error(`Cannot read connection options in a browser context.`);
    }
}

/**
 * Dummy class for replacement via `package.json` in browser builds.
 *
 * If we don't include these functions typeorm will throw an error on runtime
 * as well as during webpack builds.
 */
export class ConnectionOptionsXmlReader {
    async read(path: string) {
        throw new Error(`Cannot read connection options in a browser context.`);
    }
}

/**
 * Dummy class for replacement via `package.json` in browser builds.
 *
 * If we don't include these functions typeorm will throw an error on runtime
 * as well as during webpack builds.
 */
export class ConnectionOptionsYmlReader {
    async read(path: string) {
        throw new Error(`Cannot read connection options in a browser context.`);
    }
}

/**
 * Dummy class for replacement via `package.json` in browser builds.
 *
 * If we don't include these functions typeorm will throw an error on runtime
 * as well as during webpack builds.
 */
export class ConnectionOptionsReader {
    async all() {
        throw new Error(`Cannot read connection options in a browser context.`);
    }

    async get() {
        throw new Error(`Cannot read connection options in a browser context.`);
    }

    async has() {
        throw new Error(`Cannot read connection options in a browser context.`);
    }
}
